import React from "react";
import {
  useNotify,
  useRefresh,
  useRedirect,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  email,
  ReferenceInput,
  ReferenceArrayInput,
  BooleanInput,
  FormDataConsumer
} from 'react-admin';
import sortBy from 'lodash/sortBy';
import { FilteredSelectInput, FilteredSelectArrayInput, FreeDidListInput, CreateToolbar, FreeIpListInput } from "../../lib/components";
import compareName from '../../lib/compareName';
import parsePhoneNumber from 'libphonenumber-js/max'
import { parse } from 'query-string';
import LineService from "../../services/LineService";


const BulkRequestCreateTypeCreate = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const voicemailLanguage = (lang) => {
    return lang.split('_')[0];
  }

  const greetingLanguage = (number) => {
    if (number.match(/^\+33/)) {
      return 'fr';
    }
    if (number.match(/^\+972/)) {
      return 'he';
    }
    return 'en';
  }

  const transform = data => {
    console.log("HEHEEHEHEHEH")
    let sims = [];
    let sim = {};
    let plan = null;
    if (typeof data.icc_id !== "undefined" && data.icc_id.trim() !== "") {
      sims.push({ icc_id: data.icc_id.trim() });
    }
    let topups = [];
    if (typeof data.topup !== "undefined" && data.topup !== "") {
      topups.push({ topup_name: data.topup })
    }

    let dids = [];
    let clids = [];
    let primaryDidPayload = null;
    let portInRequest = null;
    let payload = null;

    if (typeof data.portin_did_number != "undefined" && data.portin_did_number !== "" && data.authentication_type !== "") {
      portInRequest = { number: data.portin_did_number, identity_number: data.identity_number, authentication_type: data.authentication_type }
      primaryDidPayload = { number: data.portin_did_number }
      clids.push({ caller_id: data.portin_did_number, service: "voice", destination_group_name: "IL" });
      clids.push({ caller_id: data.portin_did_number, service: "sms", destination_group_name: "IL" });
    } else {
      primaryDidPayload = { number: data.primary_number };
      clids.push({ caller_id: data.primary_number, service: "voice", destination_group_name: "IL" });
      clids.push({ caller_id: data.primary_number, service: "sms", destination_group_name: "IL" });
    }
    if (data.has_voicemail) {
      primaryDidPayload.voicemail = {
        email: data.email,
        fullname: data.email,
        password: "1234",
        timezone: 'israel',
        language: voicemailLanguage(data.language),
        greeting_language: 'he',
        are_recordings_saved_on_server: false,
        are_recordings_sent_to_email: true
      }
    }

    if (data.has_tenant_voice_route) {
      primaryDidPayload.tenant_voice_routes = data.tenant_voice_route_ids.map((tenant_voice_route_id) => ({ tenant_voice_route_id: tenant_voice_route_id }));
    }

    if (data.has_tenant_smpp_route) {
      primaryDidPayload.tenant_smpp_route = { tenant_smpp_route_id: data.tenant_smpp_route_id };
    }

    dids.push(primaryDidPayload);
    if (dids.length > 0 && typeof data.secondary_number !== "undefined" && data.secondary_number !== "") {
      let secondaryDidPayload = { number: data.secondary_number }
      if (data.has_voicemail) {
        secondaryDidPayload.voicemail = {
          email: data.email,
          fullname: data.email,
          password: "1234",
          timezone: 'israel',
          language: voicemailLanguage(data.language),
          greeting_language: greetingLanguage(data.secondary_number),
          are_recordings_saved_on_server: false,
          are_recordings_sent_to_email: true
        }
      }
      dids.push(secondaryDidPayload);
      if (typeof data.secondary_number_clid_group !== "undefined" && data.secondary_number_clid_group !== "") {
        let pn = parsePhoneNumber(data.secondary_number);
        clids.push({ caller_id: data.secondary_number, service: "voice", destination_group_name: data.secondary_number_clid_group });
        if (["MOBILE", "FIXED_LINE_OR_MOBILE"].includes(pn.getType())) {
          clids.push({ caller_id: data.secondary_number, service: "sms", destination_group_name: data.secondary_number_clid_group });
        }
      }
    }

    if (data.has_custom_ip) {
      if (typeof data.ip !== "undefined" && data.ip !== "") {
        sim.ip_addresses = [{ ip: data.ip }];
      }
    }

    if (typeof data.plan_name !== "undefined" && data.plan_name !== "") {
      plan = { plan_name: data.plan_name };
    }

    return {
      type: "create",
      external_id: data.external_id,
      email: data.email,
      identity_number: data.identity_number,
      language: data.language,
      sims: sims,
      plan: plan,
      dids: dids,
      port_in_request: portInRequest,
      topups: topups,
      clids: clids,
      local_apn: data.local_apn
    }
  }

  const onFailure = (error) => {
    notify(`Could not create BulkRequest: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`BulkRequest "${data.id}" created`);
    redirect(`/bulk_requests/${data.id}/show`);
  };

  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} transform={transform}>
      <SimpleForm toolbar={<CreateToolbar />} initialValues={{ "identity_number": process.env.REACT_APP_CREATE_LINE_DEFAULT_IDENTITY_NUMBER, "language": process.env.REACT_APP_CREATE_LINE_DEFAULT_LANGUAGE, "email": process.env.REACT_APP_CREATE_LINE_DEFAULT_EMAIL, "secondary_number_clid_group": process.env.REACT_APP_CREATE_LINE_DEFAULT_SECONDARY_NUMBER_CLID_GROUP, "authentication_type": "sms_code" }}>
        <TextInput fullWidth source="external_id" label="External ID" />
        <TextInput fullWidth source="identity_number" label="Teoudat Zeout" validate={required()} />
        <TextInput fullWidth label="Email" source="email" type="email" validate={[required(), email()]} />
        <SelectInput fullWidth source="language" choices={[
          { id: 'en_US', name: 'English' },
          { id: 'fr_FR', name: 'French' },
          { id: 'he_IL', name: 'Hebrew' },
        ]} validate={required()} />
        <TextInput fullWidth source="icc_id" label="SIM Number" />
        <BooleanInput source="has_port_in_request" label="Do you want to port in a number ?" fullWidth />
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) => formData.has_port_in_request &&
            <React.Fragment>
              <TextInput fullWidth source="portin_did_number" label="Primary number" validate={required()} {...rest} />
              <SelectInput fullWidth source="authentication_type" choices={[
                { id: 'sms_code', name: 'SMS' },
                { id: 'ivr', name: 'Call' }
              ]} validate={required()} />
            </React.Fragment>
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => !formData.has_port_in_request &&
            <FreeDidListInput
              fullWidth
              source="primary_number"
              filter={(data) => {
                return data.filter((did) => did.number.match(/^\+972/))
              }}
              validate={required()}
            />
          }
        </FormDataConsumer>
        <FreeDidListInput
          fullWidth
          source="secondary_number"
          filter={(data) => {
            return data.filter((did) => !did.number.match(/^\+972/))
          }}
        />
        <SelectInput
          fullWidth
          source="secondary_number_clid_group"
          label="Secondary Number CLID Group"
          choices={[
            { id: 'EU', name: 'EU' },
            { id: 'US', name: 'US' },
            { id: 'INT', name: 'INT' },
          ]} />
        <ReferenceInput
          fullWidth
          source="plan_name"
          reference="plans"
          allowEmpty
          filter={process.env.REACT_APP_TENANT_ID_SHORTCODE !== "" ? ({ tenant_id: `${process.env.REACT_APP_TENANT_ID_SHORTCODE}` }) : ({})}
          sort={{}}
          perPage={10000}
        >
          <FilteredSelectInput optionText="name" optionValue="name" filter={(choices) => {
            return choices.sort(compareName)
          }} />
        </ReferenceInput>
        <ReferenceInput
          fullWidth
          source="topup"
          reference="topups"
          allowEmpty
          filter={process.env.REACT_APP_TENANT_ID_SHORTCODE !== "" ? ({ tenant_id: `${process.env.REACT_APP_TENANT_ID_SHORTCODE}` }) : ({})}
          sort={{}}
          perPage={10000}
        >
          <FilteredSelectInput
            optionText="name"
            optionValue="name"
            filter={(choices) => {
              return choices.sort(compareName)
            }}
          />
        </ReferenceInput>
        <BooleanInput source="has_voicemail" label="Do you want a voicemail ?" fullWidth />
        <SelectInput source="local_apn" choices={LineService.getApnList()} />
        <BooleanInput source="has_custom_ip" label="Do you want to setup a custom ip ?" fullWidth />
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) => formData.has_custom_ip &&
            <FreeIpListInput
              fullWidth
              source="ip"
              validate={required()}
            />
          }
        </FormDataConsumer>
        <BooleanInput source="has_tenant_voice_route" label="Do you want to setup a voice route ?" fullWidth />
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) => formData.has_tenant_voice_route &&
            <ReferenceArrayInput
              fullWidth
              source="tenant_voice_route_ids"
              reference="tenant_voice_routes"
              allowEmpty
              validate={required()}
              filter={process.env.REACT_APP_TENANT_ID_SHORTCODE !== "" ? ({ tenant_id: `${process.env.REACT_APP_TENANT_ID_SHORTCODE}` }) : ({})}
              sort={{}}
              perPage={10000}
            >
              <FilteredSelectArrayInput optionText="voice_route" optionValue="id" filter={(choices) => {
                return sortBy(choices, [(o) => { return o.voice_route }]);
              }} />
            </ReferenceArrayInput>
          }
        </FormDataConsumer>
        <BooleanInput source="has_tenant_smpp_route" label="Do you want to setup a smpp route ?" fullWidth />
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) => formData.has_tenant_smpp_route &&
            <ReferenceInput
              fullWidth
              source="tenant_smpp_route_id"
              reference="tenant_smpp_routes"
              allowEmpty
              validate={required()}
              filter={process.env.REACT_APP_TENANT_ID_SHORTCODE !== "" ? ({ tenant_id: `${process.env.REACT_APP_TENANT_ID_SHORTCODE}` }) : ({})}
              sort={{}}
              perPage={10000}
            >
              <FilteredSelectInput optionText="smpp_route" optionValue="id" filter={(choices) => {
                return choices;
              }} />
            </ReferenceInput>
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export default BulkRequestCreateTypeCreate;