import * as React from 'react';
import {
  TopToolbar,
  Button,
  EditButton,
  Show,
  TabbedShowLayout,
  Tab,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  ArrayField,
  BooleanField,
  ReferenceField,
  useRedirect
} from 'react-admin'
import CheckIcon from '@material-ui/icons/Check';
import SimCardIcon from '@material-ui/icons/SimCard';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { useMediaQuery } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import { TextArrayField } from '../../lib/components';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import find from 'lodash/find';
import get from 'lodash/get';
import BalanceField from './BalanceField';
import CloseButton from './CloseButton';
import DidMenu from './DidMenu';
import ImeiField from './ImeiField';
import PortableNumberField from './PortableNumberField';
import LineSimEvents from './LineSimEvents';
import LineShowAside from './LineShowAside';
import IccIdField from './IccIdField';
import TacButton from './TacButton';
import HasPelephoneOpl from '../sims/HasPelephoneOpl';
import SipTrunkChangePasswordButton from './SipTrunkChangePasswordButton';
import PortableNumberButton from './PortableNumberButton';
import LineDidInfos from './LineDidInfos';

const DidField = ({ record }) => {
  const { number, clids } = record;
  return (
    <span key={`did${record.id}`}>{number}<br /><span style={{ fontSize: '0.75rem' }}>
      {clids && clids.length > 0 && (<span>&nbsp;(</span>)}
      {clids && clids.map(clid => (
        <span key={`clid${clid.id}`}>&nbsp;{clid.destination_group.name}<sub>{clid.service}</sub></span>
      ))}
      {clids && clids.length > 0 && (<span>&nbsp;)</span>)}
    </span>
    </span>)
}
DidField.defaultProps = { label: 'Number' };

const VoicemailField = ({ record }) => {
  const { voicemails } = record;
  let voicemail = find(voicemails, { end_at: null });
  return (
    <span key={`voicemail${record.id}`} style={{ color: 'green' }}>
      {voicemail && (<CheckIcon />)}
    </span>
  );
}
VoicemailField.defaultProps = { label: 'Voicemail' };

const RingbackToneField = ({ record }) => {
  const { ringback_tones } = record;
  let ringbackTone = find(ringback_tones, { end_at: null });
  return (
    <span key={`ringbackTone${record.id}`} style={{ color: 'green' }}>
      {ringbackTone && (<CheckIcon />)}
    </span>
  );
}
RingbackToneField.defaultProps = { label: 'RingbackTone' };

const SmsForwarderField = ({ record }) => {
  const { sms_forwarder_settings } = record;
  let smsForwarderSetting = find(sms_forwarder_settings, { end_at: null });
  return (
    <span key={`sms_forwarder_settings${record.id}`} title={smsForwarderSetting && smsForwarderSetting.email_recipient_address} style={{ color: 'green' }} >
      {smsForwarderSetting && (<CheckIcon />)}
    </span>
  );
}
SmsForwarderField.defaultProps = { label: 'SmsFwd' };

const TenantSmppRouteField = ({ record }) => {
  const { tenant_smpp_routes } = record;
  let TenantSmppRouteSetting = find(tenant_smpp_routes, { end_at: null });
  return (
    <span key={`tenant_smpp_routes${record.id}`} title={TenantSmppRouteSetting && TenantSmppRouteSetting.tenant_smpp_route_id} style={{ color: 'green' }} >
      {TenantSmppRouteSetting && (<CheckIcon />)}
    </span>
  );
}
TenantSmppRouteField.defaultProps = { label: 'SmppRoute' };

const TenantVoiceRouteField = ({ record }) => {
  const { tenant_voice_routes } = record;
  let TenantVoiceRouteSetting = find(tenant_voice_routes, { end_at: null });
  return (
    <span key={`tenant_voice_routes${record.id}`} title={TenantVoiceRouteSetting && TenantVoiceRouteSetting.tenant_voice_route_id} style={{ color: 'green' }} >
      {TenantVoiceRouteSetting && (<CheckIcon />)}
    </span>
  );
}
TenantVoiceRouteField.defaultProps = { label: 'VoiceRoute' };


const SendOta = ({ record, lineId }) => {
  const redirect = useRedirect();

  if (typeof record === "undefined" || record.end_at !== null) {
    return (<React.Fragment></React.Fragment>);
  }

  const sendOta = () => {
    redirect(`/ota_requests/create?line_id=${lineId}&sim_id=${record.id}&tenant_id=${record.sim.tenant_id}`);
  }
  return (<Button label="OTA" onClick={sendOta}><SimCardIcon /></Button>)
}

const styles = {
  root: {
    '& .MuiTab-root': {
      minWidth: "130px"
    },
    '& .subtitle .MuiInputLabel-shrink': {
      fontSize: "1em",
      marginTop: "1em",
      marginBottom: "0.5em",
      borderBottom: "1px solid #ddd",
      width: "100%",
      paddingBottom: "0.5em",
      color: "#203890",
      transform: "translate(0, 1.5px) scale(1)"
    },
  }
}

const LineShow = (props) => {
  const lineId = props.id;
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const classes = props.classes;

  return (
    <Show
      aside={<LineShowAside />}
      actions={<React.Fragment />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="Infos">
          <BoxedShowLayout className="BoxedShowLayout">
            <RaBox display="flex" flexWrap="wrap">
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="external_id" label="External ID" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <EmailField source="email" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="identity_number" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="language" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="local_apn" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="roaming_apn" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <DateField source="inserted_at" label="Creation Date" showTime />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <ReferenceField label="Tenant" source="tenant_id" reference="tenants" link={false}>
                  <TextField source="name" />
                </ReferenceField>
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <BooleanField source="is_abroad_roaming_enabled" label="International Roaming Allowed" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <BooleanField source="is_volte_enabled" label="VoLTE Allowed" />
              </RaBox>
            </RaBox>
          </BoxedShowLayout>
        </Tab>
        <Tab label="Dids">
          <ArrayField source="dids" addLabel={false}>
            <Datagrid expand={<LineDidInfos lineId={lineId} />} rowClick="expand">
              <DidField />
              <DateField source="start_at" showTime label="Start" />
              <DateField source="end_at" showTime label="End" />
              <PortableNumberButton />
              <VoicemailField />
              <RingbackToneField />
              <SmsForwarderField />
              <TenantSmppRouteField />
              <TenantVoiceRouteField />
              <DidMenu lineId={lineId} />
              <CloseButton reference="dids" lineId={lineId} style={{ marginLeft: '0.5em' }} />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Sims & SIP">
          <ArrayField source="sims" addLabel={true} label="SIMS" className="subtitle">
            <Datagrid expand={<LineSimEvents />} rowClick="expand">
              <IccIdField source="icc_id" label="ICCID" />
              <TextArrayField source="ip_addresses" path="ip" />
              <TextArrayField source="sim.imsis" path="imsi" label="Imsis" />
              <BooleanField source="is_main" />
              <DateField source="start_at" showTime />
              <DateField source="end_at" showTime />
              <HasPelephoneOpl fromLine={true} />
              <SendOta lineId={lineId} />
              <CloseButton reference="sims" lineId={lineId} />
            </Datagrid>
          </ArrayField>
          <ArrayField source="sip_trunks" addLabel={true} label="SIP TRUNKS" className="subtitle">
            <Datagrid>
              <TextField source="trunk_type" />
              <TextField source="username" />
              <TextField source="password" />
              <TextField source="sip_domain.domain" label="Domain" />
              <TextField source="dial_plan.name" label="Dialplan" />
              <TextField source="number_of_concurrent_channels" label="CC" />
              <DateField source="start_at" showTime />
              <DateField source="end_at" showTime />
              <CloseButton reference="sip_trunks" lineId={lineId} />
              <SipTrunkChangePasswordButton lineId={lineId} />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Plans">
          <ArrayField source="plans" addLabel={false}>
            <Datagrid>
              <TextField source="plan.name" label="Name" />
              <TextField source="external_id" label="External ID" />
              <DateField source="start_at" showTime />
              <DateField source="end_at" showTime />
              <DateField source="provisioned_at" showTime />
              <CloseButton reference="plans" lineId={lineId} />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Suspensions">
          <ArrayField source="suspensions" addLabel={false}>
            <Datagrid>
              <TextField source="suspension.type" label="Type" />
              <DateField source="start_at" showTime />
              <DateField source="end_at" showTime />
              <CloseButton reference="suspensions" lineId={lineId} />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Balances">
          <ArrayField source="balances" addLabel={false}>
            <Datagrid>
              <TextField source="uuid" />
              <TextField source="id" />
              <DateField source="expiration_date" showTime />
              <TextArrayField source="categories" linkType={false} />
              <BalanceField source="value" />
              <BalanceField source="initial_value" />
              {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
                <TextField source="weight" />
              )}
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="IMEI">
          <ArrayField source="imeis" addLabel={false}>
            <Datagrid>
              <ImeiField source="imei" />
              <TacButton source="tac" />
              <DateField source="created_at" showTime />
            </Datagrid>
          </ArrayField>
        </Tab>
        {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
          <Tab label="HR Operators">
            <ArrayField source="line_home_roaming_operators" addLabel={false}>
              <Datagrid>
                <TextField source="ref" />
                <BooleanField source="is_explicit" />
                <DateField source="start_at" showTime />
                <DateField source="end_at" showTime />
              </Datagrid>
            </ArrayField>
          </Tab>
        )}
        {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
          <Tab label="Phone.app">
            <ArrayField source="line_tragofone_accounts" addLabel={false}>
              <Datagrid>
                <TextField source="tragofone_account_username" label="Username" />
                <TextField source="tragofone_account_password" label="Password" />
                <DateField source="start_at" showTime />
                <DateField source="end_at" showTime />
                <CloseButton reference="line_tragofone_accounts" lineId={lineId} />
              </Datagrid>
            </ArrayField>
          </Tab>
        )}
      </TabbedShowLayout>
      {/**/}
    </Show>
  )
};

export default withStyles(styles)(LineShow);
